import classNames from 'classnames';

interface IndicatorProps {
  className?: string;
  currentNum: number;
  totalNum: number;
  indicator: 'number' | 'dot' | 'none';
  dotColor?: 'light' | 'dark';
  numberColor?: 'light' | 'dark';
  direction?: 'left' | 'right';
}

export const Indicator = ({
  className,
  currentNum,
  totalNum,
  indicator,
  dotColor,
  numberColor,
  direction,
}: IndicatorProps) => {
  if (indicator === 'number') {
    return (
      <div
        className={classNames(
          'text-new-Body2-medium absolute bottom-16  z-20 rounded-[50px] px-9 py-1',
          direction === 'right' ? 'right-16' : 'left-16',
          numberColor === 'light' ? 'bg-white/[.42]' : 'bg-new-black-50',
          className,
        )}
      >
        <span className="text-white">{currentNum + 1}</span>
        <span className="text-new-white-50">/{totalNum}</span>
      </div>
    );
  } else if (indicator === 'dot') {
    return (
      <>
        {dotColor === 'light' ? (
          // background gray + white dot
          <div className={classNames('mt-[-8px] flex justify-center pb-8', className)}>
            <div className="flex h-16 flex-row justify-center gap-2 rounded-xl bg-gray-400 px-8 py-6">
              {Array.from(Array(totalNum).keys()).map((_, index) => (
                <div
                  key={'dot-' + index}
                  className={`${
                    index === currentNum ? 'bg-white' : 'bg-gray-300'
                  } h-4 w-4 rounded-full`}
                />
              ))}
            </div>
          </div>
        ) : (
          // default black dot
          <div className={classNames('mt-10', className)}>
            <div className="flex flex-row justify-center gap-6">
              {Array.from(Array(totalNum).keys()).map((_, index) => (
                <div
                  key={'dot-' + index}
                  className={`${
                    index === currentNum ? 'bg-gray-900' : 'bg-gray-300'
                  } mx-1 h-6 w-6 rounded-full`}
                />
              ))}
            </div>
          </div>
        )}
      </>
    );
  }
  return <></>;
};
