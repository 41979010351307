import { useEffect, useMemo, useState } from 'react';

import { LogEvent } from '@analytics';
import { useGetUser } from '@db/collections';
import { Carousel, CarouselApi, CarouselContent, CarouselItem } from 'design-system';
import Image from 'next/image';
import Link from 'next/link';

import { Indicator } from '@components';

interface HomeEventContent {
  url: string;
  src: string;
  element?: React.ReactNode;
  alt: string;
  width: number;
  height: number;
}

const HomeEventContents: HomeEventContent[] = [
  {
    url: '/event/cardoc',
    src: '/events/cardoc/home-banner.png',
    alt: '카닥 쿠폰 이벤트',
    width: 500,
    height: 252,
  },
  {
    url: '/fast-license',
    src: '/home/fast-license.png',
    alt: '빠르게 면허 따기',
    width: 500,
    height: 252,
  },

  {
    url: '/event/lowest?isBack=true',
    src: '/home/event-lowest.png',
    alt: '최저가가 아니면 차액의 1.5배 보상해요',
    width: 500,
    height: 252,
  },
  {
    url: '/event/together',
    src: '/home/event-together.png',
    alt: '친구랑 같이 할인받으며 면허따기',
    width: 500,
    height: 252,
  },
];

export const HomeEventBanner = () => {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState<number>(0);
  const { user } = useGetUser();

  const carouselContents = useMemo(
    () => [
      HomeEventContents[0],
      // ...(!user || user.orders.length === 0
      //   ? [
      //       {
      //         url: '/event/first-come',
      //         src: '/events/first-come/first-come-banner.png',
      //         element: (
      //           <div className="relative bg-[rgb(35,37,35)]">
      //             <div className="absolute left-[6.66%] top-[15.73%]">
      //               <div className="text-new-Sub-Title text-[5.83vw] text-white min-[500px]:text-[29.16px]">
      //                 <div className="flex items-center gap-5">
      //                   <h2>{firstComeLabel}</h2>
      //                   <Image
      //                     src="/events/first-come/red-lightning.png"
      //                     width={11}
      //                     height={15}
      //                     alt="선착순 할인 이벤트 아이콘"
      //                   />
      //                 </div>
      //                 <span className="text-new-DTRed-400">선착순 4만원 할인 </span>
      //                 이벤트
      //               </div>
      //               <div className="text-new-white-50 mt-4 text-[3.89vw] min-[500px]:text-[19.44px]">
      //                 단 한명에게만 주어지는 기회!
      //               </div>
      //             </div>
      //             <Image
      //               src="/events/first-come/first-come-banner.png"
      //               alt="선착순 할인 이벤트"
      //               width={500}
      //               height={178}
      //               className="w-full"
      //             />
      //           </div>
      //         ),
      //         alt: '신규 가입자 경품 증정 이벤트',
      //         width: 498,
      //         height: 252,
      //       },
      //     ]
      //   : []),
      ...HomeEventContents.slice(1),
    ],
    [user],
  );

  useEffect(() => {
    if (!api) return;

    api.scrollTo(current);
  }, [current]);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCurrent(api.selectedScrollSnap());

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap());
    });
  }, [api]);

  useEffect(() => {
    api?.reInit();
  }, [carouselContents]);

  return (
    <div className="relative flex justify-center">
      <Carousel plugin={{ autoPlay: false }} setApi={setApi} opts={{ loop: true }}>
        <CarouselContent>
          {carouselContents.map((content, index: number) => {
            const { url, src, alt, width, element, height } = content;
            return (
              <CarouselItem key={'home-event-banner-' + index}>
                <Link
                  href={url}
                  onClick={() => {
                    LogEvent.활성화.homeCTA(`배너 - ${alt}`);
                    LogEvent.활성화.homeBanner(url);
                  }}
                >
                  {element ?? <Image src={src} alt={alt} width={width} height={height} />}
                </Link>
              </CarouselItem>
            );
          })}
        </CarouselContent>
      </Carousel>
      <Indicator
        currentNum={current}
        totalNum={carouselContents.length}
        indicator="number"
        className="absolute bottom-32 left-auto right-16 w-fit"
      />
    </div>
  );
};
